import { useFormikContext } from 'formik';
import React, { useState, useEffect } from 'react';
import TextAreaField from '../UI/BasicForm/TextAreaField/TextAreaField';
import SelectField from '../UI/BasicForm/SelectField/SelectField';
import YearPickerField from '../UI/BasicForm/DatePickerField/YearPickerField';
import classes from './QuoteRequestForm.module.css';
import RadioButtonsGrouped from '../UI/BasicForm/RadioButtonsGrouped/RadioButtonsGrouped';
import QuoteRequestFormStepThree from './QuoteRequestFormStepThree';

import 'react-datepicker/dist/react-datepicker.css';
import { RequestQuoteFormInterface } from '../../utils/interface';
import {
  useGetAll_OutageSeasons,
  useGetAll_PartsRequestReasons,
  useGetAll_ServiceRequestReasons,
  useGetAll_RequestPriority
} from '../../containers/PartsAndService/QuoteRequestHttpServices';
import DatePickerFields from '../UI/BasicForm/DatePickerField/DatePickerFields';

const QuoteRequestFormStepTwo = (props: stepTwoInterface) => {
  const { values, setFieldValue } = useFormikContext<RequestQuoteFormInterface>();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [requestPriority, setRequestPriority] = useState<{ id: number; name: string }[]>([{ id: 0, name: 'Select a priority' }]);
  const [requestReasons, setRequestReasons] = useState<{ id: number; name: string }[]>([{ id: 0, name: 'Select a reason' }]);
  const [outageSeasons, setOutageSeasons] = useState<{ id: number | string; name: string }[]>([
    { id: '', name: 'Select an outage seasons' }
  ]);
  const [isServiceRequest, setIsServiceRequest] = useState<boolean>(false);
  const [showPlannedOutage, setShowPlannedOutage] = useState<boolean>(false);

  useEffect(() => {
    setFieldValue('quoteRequest.outageYear', '');
    setFieldValue('quoteRequest.outageSeasonId', '');
    setIsServiceRequest(Number(values.quoteRequest.quoteRequestType) === 1);
  }, [setFieldValue, values.quoteRequest.quoteRequestType]);

  useEffect(() => {
    if (values.quoteRequest.isPlannedOutage === 'true') {
      setFieldValue('quoteRequest.outageYear', startDate?.getFullYear());
    } else {
      setFieldValue('quoteRequest.outageYear', '');
      setFieldValue('quoteRequest.outageSeasonId', '');
    }
  }, [setFieldValue, startDate, values.quoteRequest.isPlannedOutage]);

  useEffect(() => {
    const ambiguousRequestOptions = ['budgetary', 'inventory', 'tax'];
    const requestReasonId = isServiceRequest ? values.quoteRequest.serviceRequestReasoningId : values.quoteRequest.partsRequestReasoningId;
    if (requestReasonId === 0) {
      setShowPlannedOutage(false);
      setFieldValue('quoteRequest.isPlannedOutage', 'false');
    } else {
      const requestReason = requestReasons.find((reason) => reason.id === requestReasonId);
      if (!requestReason) {
        setShowPlannedOutage(false);
        setFieldValue('quoteRequest.isPlannedOutage', 'false');
      } else {
        if (ambiguousRequestOptions.includes(requestReason.name.toLocaleLowerCase())) {
          setShowPlannedOutage(true);
          setFieldValue('quoteRequest.isPlannedOutage', 'false');
        } else {
          setShowPlannedOutage(false);
          if (requestReason.name.toLocaleLowerCase() === 'planned outage') {
            setFieldValue('quoteRequest.isPlannedOutage', 'true');
          } else {
            setFieldValue('quoteRequest.isPlannedOutage', 'false');
          }
        }
      }
    }
  }, [
    isServiceRequest,
    requestReasons,
    setFieldValue,
    values.quoteRequest.partsRequestReasoningId,
    values.quoteRequest.serviceRequestReasoningId
  ]);

  const handleOnchange = (date: Date) => {
    setStartDate(date);
    setFieldValue('quoteRequest.outageYear', date.getFullYear());
  };

  // Api fetch start-----------------------------------------------------//
  const onSuccessRP = (requestPriority: { data: [{ id: number; order: number; title: string }] }) => {
    setRequestPriority(
      requestPriority.data
        .map((option: { id: number; order: number; title: string }) => ({ id: option.id, name: option.title.toLowerCase() }))
        .sort()
    );
  };
  const onErrorRP = (error: any) => {
    setRequestPriority([]);
  };
  useGetAll_RequestPriority(onSuccessRP, onErrorRP);
  // Api fetch end-------------------------------------------------------//

  // Api fetch start-----------------------------------------------------//
  const onSuccessPRR = (partsRequestReasons: { data: [{ reasoningId: number; name: string; description: string }] }) => {
    setRequestReasons(
      partsRequestReasons.data
        .map((option: { reasoningId: number; name: string; description: string }) => ({
          id: option.reasoningId,
          name: option.name.toLowerCase()
        }))
        .sort()
    );
  };
  const onErrorPRR = (error: any) => {
    setRequestReasons([{ id: 0, name: 'Select a reason' }]);
  };
  useGetAll_PartsRequestReasons(onSuccessPRR, onErrorPRR, !isServiceRequest);

  const onSuccessSRR = (serviceRequestReasons: { data: [{ reasoningId: number; name: string; description: string }] }) => {
    setRequestReasons(
      serviceRequestReasons.data
        .map((option: { reasoningId: number; name: string; description: string }) => ({
          id: option.reasoningId,
          name: option.name.toLowerCase()
        }))
        .sort()
    );
  };
  const onErrorSRR = (error: any) => {
    setRequestReasons([{ id: 0, name: 'Select a reason' }]);
  };

  useGetAll_ServiceRequestReasons(onSuccessSRR, onErrorSRR, isServiceRequest);

  // Api fetch end-------------------------------------------------------//
  // Api fetch start-----------------------------------------------------//
  const onSuccessOS = (partsRequestReasons: { data: [{ outageSeasonId: number; name: string; description: string }] }) => {
    setOutageSeasons(
      partsRequestReasons.data
        .map((option: { outageSeasonId: number; name: string; description: string }) => ({
          id: option.outageSeasonId,
          name: option.name.toLowerCase()
        }))
        .sort()
    );
  };
  const onErrorOS = (error: any) => {
    setOutageSeasons([]);
  };
  useGetAll_OutageSeasons(onSuccessOS, onErrorOS);
  // Api fetch end-------------------------------------------------------//

  return (
    <>
      <div>
        <TextAreaField
          name="quoteRequest.description"
          height={64}
          labelName="Request Description*"
          placeholder="Description"
          className=""
        />
      </div>
      <div className={classes.flex}>
        <div className={classes.flexCol}>
          <SelectField
            defaultValue={0}
            placeholder={values.quoteRequest.quotePriority}
            options={requestPriority}
            name={'quoteRequest.quotePriorityId'}
            labelName={'Quote Turnaround Priority*'}
          />
        </div>
        <div className={classes.flexCol}>
          <DatePickerFields
            labelName={'Quote Needed Date*'}
            name="quoteRequest.quoteNeededDate"
          />
        </div>
      </div>
      <div className={classes.flex}>
        <div className={classes.flexCol}>
          <SelectField
            defaultValue={0}
            placeholder={values.quoteRequest.quotePriority}
            options={requestPriority}
            name={'quoteRequest.partsPriorityId'}
            labelName={!isServiceRequest ? 'Parts Delivery Priority*' : 'Service Delivery Priority*'}
          />
        </div>
        <div className={classes.flexCol}>
          <DatePickerFields
            labelName={!isServiceRequest ? 'Parts Needed Date*' : 'Service Needed Date*'}
            name="quoteRequest.partsNeededDate"
          />
        </div>
      </div>

      <div className={classes.flex}>
        <div className={classes.flexCol}>
          <SelectField
            defaultValue={0}
            options={requestReasons}
            name={isServiceRequest ? 'quoteRequest.serviceRequestReasoningId' : 'quoteRequest.partsRequestReasoningId'}
            labelName={isServiceRequest ? 'Service Request Reasoning*' : 'Parts Request Reasoning*'}
          />
        </div>
        <div className={classes.flexCol}></div>
      </div>

      {showPlannedOutage && (
        <div className={classes.flexCol}>
          <div>
            <RadioButtonsGrouped
              name="quoteRequest.isPlannedOutage"
              display="inline"
              firstRadioName="Yes"
              firstRadioValue="true"
              SecondRadioName="No"
              SecondRadioValue="false"
              labelName="Is this quote related to a planned outage?"
              className=""
            />
          </div>
        </div>
      )}

      {values.quoteRequest.isPlannedOutage === 'true' && (
        <div className={classes.flex}>
          <div className={classes.flexCol}>
            <SelectField
              placeholder="Select an outage season"
              defaultValue={0}
              options={outageSeasons}
              name={'quoteRequest.outageSeasonId'}
              labelName={'Outage Season*'}
            />
          </div>
          <div className={classes.flexCol}>
            <YearPickerField
              name="quoteRequest.outageYear"
              dateFormat={'yyyy'}
              handleOnchange={handleOnchange}
              setStartDate={setStartDate}
              startDate={startDate}
              labelName="Outage Year*"
              className=""
              placeholder="Select Outage Year"
            />
          </div>
        </div>
      )}

      <hr />
      <QuoteRequestFormStepThree handleSubmitNew={props.handleSubmitNew} />
    </>
  );
};

interface stepTwoInterface {
  handleSubmitNew?(): void;
}

export default QuoteRequestFormStepTwo;
