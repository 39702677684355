import { FieldArray, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useGetAllByUnitID_PlantEquipments } from '../../containers/PlantInformation/EquipmentsHttpServices';
import { usePlantSiteUnits } from '../../containers/Reports/ReportsHttpServices';
import useWindowSize from '../../customHooks/useWindowSize';
import { useStore } from '../../useStore';
import { CloseAllModal } from '../../utils/CloseAllModal';
import { PlantEquipmentsDATA, PlantEquipmentsDTO, RequestQuoteFormInterface, SiteUnit } from '../../utils/interface';
import Dropdown from '../Dropdown/Dropdown';
import FormSuccess from '../FormsPages/FormSuccess/FormSuccess';
import PlainSelect from '../UI/BasicForm/SelectField/PlainSelectField';
import TextAreaField from '../UI/BasicForm/TextAreaField/TextAreaField';
import TextInputField from '../UI/BasicForm/TextInputField/TextInputField';
import AttachNameplateAssetModal from './AttachNameplateAssetModal';
import AttachPartDocumentModal from './AttachPartDocumentModal';
import AttachmentCard from './AttachmentCard';
import { PartsListTemplateLink } from './PartsListTemplateLink';
import classes from './QuoteRequestForm.module.css';

const QuoteRequestFormStepThree = (props: stepThreeInterface) => {
  interface SelectedDropDownProps {
    id: number;
    name: string;
  }

  const DefaultSelectedUnit: SelectedDropDownProps = {
    id: 0,
    name: 'Select a unit'
  };

  const DefaultSelectedProductType: SelectedDropDownProps = {
    id: 0,
    name: 'Select a product type'
  };

  const DefaultSelectedEquipmentType: SelectedDropDownProps = {
    id: 0,
    name: 'Select an equipment'
  };

  const { values, setFieldValue, errors, touched } = useFormikContext<RequestQuoteFormInterface>();
  const [Quantity, setQuantity] = useState<any[]>([]);
  const { isModal, setIsModal, unitTypes, setUnitTypes } = useStore();
  const { isMobile } = useWindowSize();
  const { data: Units } = usePlantSiteUnits();
  const navigate = useNavigate();

  const [selectedUnit, setSelectedUnit] = useState<SelectedDropDownProps>(DefaultSelectedUnit);
  const [selectedProductType, setSelectedProductType] = useState<SelectedDropDownProps>(DefaultSelectedProductType);
  const [selectedEquipmentType, setSelectedEquipmentType] = useState<SelectedDropDownProps>(DefaultSelectedEquipmentType);

  const [equipmentsByUnit, setEquipmentsByUnit] = useState<{ id: number; name: string; productType: number }[]>([]);
  const [productType, setProductType] = useState<{ id: number; name: string }[]>([]);
  const [equipmentByProductType, setEquipmentByProductType] = useState<{ id: number; name: string }[]>([]);

  const [, setSelectedOption] = useState<string | null>('');
  const [, setSelectedProduct] = useState<string | null>('');
  const [, setSelectedEquipment] = useState<string | null>('');

  const [siteUnits, setSiteUnits] = useState<SiteUnit[]>([]);
  const [isServiceRequest, setIsServiceRequest] = useState<boolean>(false);

  useEffect(() => {
    setIsServiceRequest(Number(values.quoteRequest.quoteRequestType) === 1);
  }, [values.quoteRequest.quoteRequestType]);

  const handleUnitSelection = (e: React.MouseEvent<HTMLDivElement>) => {
    const sortedtypedata = e.currentTarget.getAttribute('data-id');

    if (!sortedtypedata) return;
    const selUnit = unitTypes.data.filter((u) => u.id === parseInt(sortedtypedata));
    setSelectedUnit({ ...selUnit[0] });
    setFieldValue('isUnitSelected', true);
    setSelectedProductType(DefaultSelectedProductType);
    setSelectedEquipmentType(DefaultSelectedEquipmentType);
  };
  const handleProductType = (e: React.MouseEvent<HTMLDivElement>) => {
    const selectedProductID = e.currentTarget.getAttribute('data-id');
    if (!selectedProductID) return;
    const product = productType.filter((u) => u.id === parseInt(selectedProductID));
    setSelectedProductType({ ...product[0] });
    const equipments = equipmentsByUnit
      .filter((u) => {
        return u.productType === parseInt(selectedProductID);
      })
      .map((option) => ({ id: option.id, name: option.name }));
    setEquipmentByProductType(equipments);
    setSelectedEquipmentType(DefaultSelectedEquipmentType);
  };
  const handleEquipmentSelect = (e: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>, option?: any) => {
    const selectedEquipmentID = e.currentTarget.getAttribute('data-id');
    if (!selectedEquipmentID) return;
    const EquipmentType = equipmentByProductType.filter((u) => u.id === parseInt(selectedEquipmentID));
    setSelectedEquipmentType({ ...EquipmentType[0] });
    const equipmentId = parseInt(selectedEquipmentID);
    setFieldValue('quoteRequest.equipmentId', equipmentId);
    setFieldValue('quoteRequest.equipmentName', EquipmentType[0].name);
    setFieldValue('isProductSelected', true);
  };

  useEffect(() => {
    const equipmentId = selectedEquipmentType.id ?? 0;
    const equipmentName = selectedEquipmentType.name ?? '';
    setFieldValue('quoteRequest.equipmentId', equipmentId);
    setFieldValue('quoteRequest.equipmentName', equipmentName);
  }, [selectedEquipmentType, setFieldValue]);

  // Api fetch start-----------------------------------------------------//
  const onSuccessRP = (plantEquipments: PlantEquipmentsDTO) => {
    setEquipmentsByUnit(
      plantEquipments.data
        .map((option: PlantEquipmentsDATA) => ({
          id: option.plantEquipmentId,
          name: option.equipmentName,
          productType: option.productType
        }))
        .sort((a, b) => (a.name.toLowerCase().slice(0, 1) > b.name.toLowerCase().slice(0, 1) ? 1 : -1))
    );

    setProductType(
      plantEquipments.data
        .map((option: PlantEquipmentsDATA) => ({ id: option.productType, name: option.productTypeName }))
        .filter((v, i, a) => a.findIndex((d) => d.id === v.id) === i)
        .sort((a, b) => (a.name.toLowerCase().slice(0, 1) > b.name.toLowerCase().slice(0, 1) ? 1 : -1))
    );
  };
  const onErrorRP = (error: any) => {
    setProductType && setProductType([]);
    setSelectedProductType && setSelectedProductType(DefaultSelectedProductType);
    setSelectedEquipmentType && setSelectedEquipmentType(DefaultSelectedEquipmentType);
    setEquipmentsByUnit([]);
  };
  useGetAllByUnitID_PlantEquipments(selectedUnit.id, onSuccessRP, onErrorRP);
  // Api fetch end-------------------------------------------------------//

  useEffect(() => {
    setQuantity([...new Array(51)]);
  }, []);

  useEffect(() => {
    if (Units) {
      setSiteUnits(Units);
      const type = Units.map((unit) => ({ id: unit.unitId, name: unit.name }));
      setUnitTypes?.({ data: [...type] });
    } else {
      setSiteUnits([]);
      setUnitTypes?.({ data: [{ id: 0, name: 'Select a unit ' }] });
    }
  }, [Units, setUnitTypes]);

  return (
    <>
      <div className={`${classes.qrwrpper}`}>
        <div>
          <h4>Equipment & Part Details</h4>
        </div>
        <p className={classes.para}>
          Please provide part(s) information ONLY for the unit selected below. Please create a separate quote for each unit.
        </p>
        <div className={classes.flex}>
          <div className={classes.flexCol}>
            <div>
              <label>Site Unit*</label>
            </div>
            <div
              className={
                siteUnits.length > 0
                  ? `nameplate-dropdown  inline-flex unitDropdown`
                  : `nameplate-dropdown disabled inline-flex unitDropdown`
              }
            >
              <Dropdown
                dropdowntype={''}
                options={siteUnits ? unitTypes.data : []}
                handleDropDownSelect={handleUnitSelection}
                selectedOption={selectedUnit.name}
                isError={errors?.isUnitSelected && touched?.isUnitSelected ? true : false}
                setSelectedOption={setSelectedOption!}
              />
            </div>
          </div>
        </div>
        <div className={classes.flex}>
          <div className={classes.flexCol}>
            <div>
              <label>Product Type*</label>
            </div>
            <div
              className={productType.length > 0 ? `nameplate-dropdown  flex unitDropdown` : `nameplate-dropdown disabled flex unitDropdown`}
            >
              <Dropdown
                dropdowntype={''}
                style={{ width: '100%' }}
                options={productType ? productType : []}
                handleDropDownSelect={handleProductType}
                selectedOption={selectedProductType.name}
                setSelectedOption={setSelectedProduct!}
                isError={errors?.isProductSelected && touched?.isProductSelected ? true : false}
              />
            </div>
          </div>
          <div className={classes.flexCol}>
            <div>
              <label>Equipment Name*</label>
            </div>
            <div
              className={
                equipmentsByUnit.length > 0 && selectedProductType.id > 0
                  ? `nameplate-dropdown  flex unitDropdown`
                  : `nameplate-dropdown disabled flex unitDropdown`
              }
            >
              <Dropdown
                dropdowntype={''}
                style={{ width: '100%' }}
                options={equipmentByProductType ? equipmentByProductType.sort((a, b) => a.name.localeCompare(b.name)) : []}
                handleDropDownSelect={handleEquipmentSelect}
                isError={errors.quoteRequest?.equipmentId && touched.quoteRequest?.equipmentId ? true : false}
                selectedOption={selectedEquipmentType.name}
                setSelectedOption={setSelectedEquipment!}
              />
            </div>
          </div>
        </div>
        <div>
          <p>
            Note: If the third-party equipment you are looking for does not appear in the dropdown list above, please navigate to your
            plant’s equipment list in the{' '}
            <Link
              target={'_blank'}
              to={'/plant-information'}
            >
              Plant Information
            </Link>{' '}
            page and add it. If you require Mitsubishi Power equipment to be added, please contact your Mitsubishi Power representative.
          </p>
        </div>

        {values?.quoteRequest?.quoteRequestParts && !isServiceRequest && (
          <div>
            <FieldArray name="quoteRequest.quoteRequestParts">
              {({ insert, remove, push }) => (
                <div>
                  {values?.quoteRequest?.quoteRequestParts.length > 0 && !isMobile && (
                    <div className={classes.quoteRequestPartsWrp}>
                      <div className={classes.col1}>Part Number</div>
                      <div className={classes.col2}>Part Description</div>
                      <div className={classes.col3}>Quantity</div>
                      <div className={classes.col4}></div>
                    </div>
                  )}

                  {values?.quoteRequest?.quoteRequestParts.length > 0 &&
                    values.quoteRequest.quoteRequestParts.map((friend, index) => (
                      <div
                        className={classes.quoteRequestPartsWrp}
                        key={index}
                      >
                        <div className={classes.col1}>
                          {isMobile && <div className={classes.col1}>Part Number</div>}
                          <TextInputField
                            type="text"
                            name={`quoteRequest.quoteRequestParts.${index}.partNumber`}
                            labelName=""
                            className="marginbottom0"
                            placeholder=""
                          />
                        </div>
                        <div className={classes.col2}>
                          {isMobile && <div className={classes.col2}>Part Description</div>}
                          <TextInputField
                            type="text"
                            name={`quoteRequest.quoteRequestParts.${index}.description`}
                            labelName=""
                            className="marginbottom0"
                            placeholder=""
                          />
                        </div>
                        <div className={classes.col3}>
                          <PlainSelect
                            options={Quantity}
                            name={`quoteRequest.quoteRequestParts.${index}.quantity`}
                            label={'Quantity'}
                            showTooltip={false}
                            disabled={false}
                          />
                        </div>
                        {
                          <div className={classes.col4}>
                            <div
                              className={classes.clear}
                              onClick={() => remove(index)}
                            >
                              Remove
                            </div>
                          </div>
                        }
                      </div>
                    ))}
                  <button
                    type="button"
                    className={`primaryBtn whiteBtn ${classes.buttonMargin} ${classes.addMoreBtn}`}
                    onClick={() => push({ partNumber: '', description: '', quantity: 0 })}
                  >
                    {values?.quoteRequest?.quoteRequestParts.length <= 0 ? 'Add a Part' : 'Add More Parts'}
                  </button>
                </div>
              )}
            </FieldArray>

            <hr />
            <div>
              <h4>Attach a Parts Document (Optional)</h4>
            </div>
            <p className={classes.para}>
              Please use this space to upload a parts list document with details such as part numbers, descriptions and quantity needed. You
              may organize your parts list details using the Mitsubishi Power-provided <PartsListTemplateLink text="Parts List Template" />.
            </p>
            {!values.partsDocumentBase64 && (
              <button
                type="button"
                className={`primaryBtn whiteBtn ${classes.buttonMargin} ${classes.addMoreBtn}`}
                onClick={() =>
                  setIsModal?.({
                    ...CloseAllModal,
                    isAdd: { ...isModal.isAdd, isPartsListDocument: true }
                  })
                }
              >
                Attach Document
              </button>
            )}

            {values.partsDocumentBase64 && (
              <AttachmentCard
                fileName={values.partsDocument.name}
                image={values.partsDocumentBase64}
                lastUpdated={`${new Date()}`}
                id={1}
              />
            )}
          </div>
        )}

        <hr />
        <div>
          <h4>Attach a Nameplate Asset (Required for Third-Party Equipment)</h4>
          <p className={classes.para}>
            For third-party equipment configurations where multiple nameplate assets are available, please combine multiple nameplate images
            into one file before attaching below.
          </p>
        </div>

        {(!values.nameplateBase64 || !values.nameplate.name) && (
          <button
            type="button"
            style={
              (errors?.nameplate?.file && touched?.nameplate?.file) || (errors?.nameplate?.name && touched?.nameplate?.name)
                ? { backgroundColor: '#FEE2E2', color: '#991B1B', borderColor: '#FEE2E2' }
                : {}
            }
            className={`primaryBtn whiteBtn ${classes.buttonMargin} ${classes.addMoreBtn}`}
            onClick={() =>
              setIsModal?.({
                ...CloseAllModal,
                isAdd: { ...isModal.isAdd, isAttachNameplateAsset: true }
              })
            }
          >
            Attach Nameplate
          </button>
        )}

        {values.nameplateBase64 && (
          <AttachmentCard
            fileName={values.nameplate.name}
            image={values.nameplateBase64}
            lastUpdated={`${new Date()}`}
            id={2}
          />
        )}

        <hr />

        <div>
          <TextAreaField
            name="quoteRequest.additionalComments"
            height={126}
            labelName="Additional Comment(s):"
            placeholder="Add comment(s)"
            className=""
          />
        </div>

        {isModal.isAdd.isPartsListDocument && <AttachPartDocumentModal />}
        {isModal.isAdd.isAttachNameplateAsset && <AttachNameplateAssetModal />}
        {isModal.isAddedSuccess.isQuoteRequest && (
          <FormSuccess
            modaltitlename={!isServiceRequest ? 'Parts Quote Request Submitted' : 'Service Quote Request Submitted'}
            modaltitle={''}
            isTitleCenter={true}
            comment={`Your quote has been submitted successfully. If there are any questions with your order, a Mitsubishi Power representative will contact you.`}
            modalSize={'sm'}
            button_done={true}
            onClick={props.handleSubmitNew}
            button_addmore={true}
            buttonName="Submit Another Request"
            buttonDoneOnclick={() => {
              navigate(-1);
            }}
            handleCrossIcon={() => {
              navigate(-1);
            }}
          />
        )}
      </div>
    </>
  );
};

interface stepThreeInterface {
  handleSubmitNew?(): void;
}

export default QuoteRequestFormStepThree;
