import React, { useEffect, useRef, useState } from 'react';
import classes from './QuoteRequestForm.module.css';
import Button from '../Button/Button';
import QuoteRequestFormStepTwo from './QuoteRequestFormStepTwo';
import { Form, Formik, FormikHelpers } from 'formik';
import { RequestQuoteFormInterface } from '../../utils/interface';
import RadioButtonsGrouped from '../UI/BasicForm/RadioButtonsGrouped/RadioButtonsGrouped';
import TextInputField from '../UI/BasicForm/TextInputField/TextInputField';
import SelectField from '../UI/BasicForm/SelectField/SelectField';
import { useOnError } from '../../customHooks/useOnError';
import * as Yup from 'yup';
import {
  useCreate_QuoteRequestAttachment,
  useCreate_RequestQuote,
  useGetAll_QuoteRequestType,
  useGetAll_QuoteTypes
} from '../../containers/PartsAndService/QuoteRequestHttpServices';

import { CloseAllModal } from '../../utils/CloseAllModal';
import Loader from '../Loader/Loader';
import ModalGlobal from '../UI/Modal/ModalGlobal';

import { ItemTypeCatalogIds } from '../../utils/moduleIDs';

import SelectFieldComplex from '../UI/BasicForm/SelectField/SelectFieldComplex';
import { useQueryClient } from 'react-query';
import { useStore } from '../../useStore';
import InternalUserField from './InternalUserField';
import { useLocation, useNavigate } from 'react-router-dom';
import useHandleBlocker from '../../customHooks/useHandleBlocker';
import dayjs from 'dayjs';

const QuoteRequestFormStepOne = () => {
  const { selectedPlant, setIsModal, isModal } = useStore();
  const setErrorData = useOnError();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [, setIsSubmitted] = useState<boolean>(false);

  const [isFormSubmitSuccess, setIsFormSubmitSuccess] = useState<{ api1: boolean; api2: boolean; api3: boolean }>({
    api1: false,
    api2: false,
    api3: false
  });

  const [, setSelectedQuoteType] = useState<string | null>(null);
  const [isGeneralRequestSelected, setIsGeneralRequestSelected] = useState<boolean>(false);
  const [resetInitialValues, setResetInitialValues] = useState<boolean>(false);
  const [quoteRequestId, setQuoteRequestId] = useState<number>(0);
  const [quoteRequestTypeId, setQuoteRequestTypeId] = useState<string | number | boolean | null>(-1);
  const [quoteTypeId, setQuoteTypeId] = useState<string | number | boolean | null>(0);

  const [requestTypeOption, setRequestTypeOption] = useState<{ id: number; name: string }[]>([{ id: -1, name: 'Select a request type' }]);
  const [quoteTypeOption, setQuoteTypeOption] = useState<{ id: number; name: string }[]>([{ id: 0, name: 'Select a quote type' }]);

  const { user } = useStore();
  const { setIsBlocked } = useHandleBlocker();

  // Api fetch start-----------------------------------------------------//
  const onSuccessQRT = (quoteRequestType: any) => {
    setRequestTypeOption([{ id: -1, name: 'Select a request type' }, ...quoteRequestType.data]);
  };
  const onErrorQRT = () => {};
  const { isLoading: RequestTypeLoading } = useGetAll_QuoteRequestType(onSuccessQRT, onErrorQRT);
  // Api fetch end-------------------------------------------------------//

  // Api fetch start-----------------------------------------------------//
  const onSuccessQT = (quoteType: any) => {
    setQuoteTypeOption([
      { id: 0, name: 'Select a quote type' },
      ...quoteType.data.map((d: { quoteTypeId: number; name: string }) => ({ id: d.quoteTypeId, name: d.name }))
    ]);
  };
  const onErrorQT = () => {};
  const { isLoading: QuoteTypesLoading } = useGetAll_QuoteTypes(onSuccessQT, onErrorQT);
  // Api fetch end-------------------------------------------------------//

  // Api mutate start-----------------------------------------------------//
  const { mutate: AddQuoteRequest, isLoading: isLoadingAddQuoteRequest } = useCreate_RequestQuote();
  const { mutate: AddAttachment, isLoading: isLoadingAddAttachment } = useCreate_QuoteRequestAttachment();
  const { mutate: AddNameplate, isLoading: isLoadingAddNameplate } = useCreate_QuoteRequestAttachment();
  // Api mutate end-------------------------------------------------------//

  const handleOnSubmit = (values: RequestQuoteFormInterface, formikHelpers: FormikHelpers<RequestQuoteFormInterface>) => {
    const requestsFormData = new FormData();
    const partsDocumentFormData = new FormData();
    const nameplateFormData = new FormData();
    const requests: any = values.quoteRequest;
    const partsDocumentRequests: any = values.partsDocument;
    const nameplaterequests: any = values.nameplate;

    for (let request in requests) {
      if (request === 'quoteRequestParts' && values.quoteRequest.itemTypeId === ItemTypeCatalogIds.PartsQuoteRequest) {
        const aE = (requests[request] as { partNumber: string; description: string; quantity: number }[]).filter(
          (d) => d.partNumber && d.description
        );
        aE.forEach((e, i: number) => {
          requestsFormData.append(`quoteRequestParts[${i}].partNumber`, `${e.partNumber}`);
          requestsFormData.append(`quoteRequestParts[${i}].description`, `${e.description}`);
          requestsFormData.append(`quoteRequestParts[${i}].quantity`, `${e.quantity}`);
        });
      } else if (
        (request === 'dateRequestReceivedFromCustomer' && requests.isCustomer === 'true') ||
        request === 'quoteNeededDate' ||
        (request === 'partsNeededDate' && dayjs(requests[request]).isValid())
      ) {
        requestsFormData.append(request, dayjs(requests[request]).format('MM/DD/YYYY'));
      } else {
        requestsFormData.append(request, requests[request]);
      }
    }
    for (let partsDocumentrequest in partsDocumentRequests) {
      partsDocumentFormData.append(partsDocumentrequest, partsDocumentRequests[partsDocumentrequest]);
    }
    for (let nameplateRequest in nameplaterequests) {
      nameplateFormData.append(nameplateRequest, nameplaterequests[nameplateRequest]);
    }

    AddQuoteRequest(requestsFormData, {
      onSuccess(AddQuoteRequest: any) {
        setIsBlocked(false);
        setQuoteRequestId(AddQuoteRequest.data[0].quoteRequestId);
        setIsFormSubmitSuccess((prev) => ({ ...prev, api1: true }));
        setIsModal!({
          ...CloseAllModal,
          isAddedSuccess: { ...isModal.isAddedSuccess, isQuoteRequest: true }
        });
        if (AddQuoteRequest.data[0].quoteRequestId > 0 && values.partsDocument.file) {
          AddAttachment(
            {
              ...values.partsDocument,
              quoteRequestId: AddQuoteRequest.data[0].quoteRequestId
            },
            {
              onSuccess(res: any) {
                setIsFormSubmitSuccess((prev) => ({ ...prev, api2: true }));
              },
              onError(error: unknown) {
                setErrorData(error);
                setIsFormSubmitSuccess((prev) => ({ ...prev, api2: false }));
                formikHelpers.setSubmitting(false);
              }
            }
          );
        }
        if (AddQuoteRequest.data[0].quoteRequestId > 0 && values.nameplate.file) {
          AddNameplate(
            {
              ...values.nameplate,
              quoteRequestId: AddQuoteRequest.data[0].quoteRequestId
            },
            {
              onSuccess(res: any, variables, context) {
                setIsFormSubmitSuccess((prev) => ({ ...prev, api3: true }));
              },
              onError(error: unknown) {
                setErrorData(error);
                setIsFormSubmitSuccess((prev) => ({ ...prev, api3: false }));
                formikHelpers.setSubmitting(false);
              }
            }
          );
        }
      },
      onError(error: unknown) {
        setErrorData(error);
        setIsFormSubmitSuccess((prev) => ({ ...prev, api1: false }));
        formikHelpers.setSubmitting(false);
      }
    });
  };

  useEffect(() => {
    setIsGeneralRequestSelected(false);
  }, [selectedPlant.plantId]);

  // 20230131 - Added location and previous location checks since we set enableReinitialize to false.
  // This is necessary in the event that a user changes baseOrg while adding a quote request so we can force the form to reset to default values.
  const location = useLocation();
  const usePrevious = (value: any) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });

    return ref.current;
  };
  const prevLocation = usePrevious(location);
  useEffect(() => {
    if (prevLocation && JSON.stringify(location) !== JSON.stringify(prevLocation)) {
      setResetInitialValues(true);
    }
  }, [location, prevLocation]);

  let initialValues: RequestQuoteFormInterface;

  initialValues = {
    quoteRequest: {
      quoteRequestId: 0,
      globalItemId: 0,
      baseOrgId: selectedPlant ? selectedPlant.baseOrgId : 0,
      itemTypeId: 0,
      description: '',
      quotePriorityId: 0,
      plantSiteId: selectedPlant ? selectedPlant.plantId : 0,
      quoteNeededDate: '',
      partsPriorityId: 0,
      partsNeededDate: '',
      partsRequestReasoningId: 0,
      isPlannedOutage: 'false',
      isCustomer: 'false',
      outageSeasonId: 0,
      quotePriority: '',
      outageYear: 0,
      equipmentId: 0,
      equipmentName: '',
      customerContactId: 0,
      additionalComments: '',
      quoteRequestAttachments: [],
      internalOutageId: '',
      file: '',
      requestTypeName: '',
      createdBy: '',
      customerContact: '',
      quoteRequestParts: [
        { partNumber: '', description: '', quantity: 0 },
        { partNumber: '', description: '', quantity: 0 },
        { partNumber: '', description: '', quantity: 0 }
      ],
      quoteRequestEvents: [],
      quoteRequestType: -1,
      quoteTypeId: 0,
      serviceRequestReasoningId: 0,
      dateRequestReceivedFromCustomer: ''
    },
    partsDocument: {
      quoteRequestId: quoteRequestId,
      name: '',
      quoteRequestAttachmentTypeId: 1,
      file: null
    },
    nameplate: {
      quoteRequestId: quoteRequestId,
      name: '',
      quoteRequestAttachmentTypeId: 2,
      file: null
    },
    isUnitSelected: null,
    isProductSelected: null,
    partsDocumentBase64: '',
    nameplateBase64: ''
  };

  const handleSubmitNew = () => {
    queryClient.resetQueries();
    setSelectedQuoteType(null);

    setIsModal!({
      ...CloseAllModal
    });
    setIsGeneralRequestSelected(false);
    setQuoteTypeId(initialValues.quoteRequest.quoteTypeId);
    setQuoteRequestTypeId(initialValues.quoteRequest.quoteRequestType);
    setIsFormSubmitSuccess({ api1: false, api2: false, api3: false });
  };

  return (
    <div>
      <Formik
        enableReinitialize={false} // 20230131 - Set enableReinitialize to false. Having it true caused the form to reset after adding a document.
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          quoteRequest: Yup.object().shape({
            quoteRequestType: Yup.number().min(0).required(), // 20230131 - Removed .positive() validation since 0 is a valid quote request type.
            quoteTypeId: Yup.number().positive().min(1).required(),
            description: Yup.string().required().trim(),
            baseOrgId: Yup.number().positive().min(1).required(),
            itemTypeId: Yup.number().positive().min(1).required(),
            quotePriorityId: Yup.number().positive().min(1).required(),
            plantSiteId: Yup.number().positive().min(1).required(),
            quoteNeededDate: Yup.string().required().trim(),
            dateRequestReceivedFromCustomer: Yup.string().when('isCustomer', {
              is: (isCustomer: string) => isCustomer === 'true',
              then: Yup.string().required().trim()
            }),
            quoteRequestParts: Yup.array().of(
              Yup.object().shape(
                {
                  partNumber: Yup.string().when('description', {
                    is: (description: string) => description?.length > 0,
                    then: Yup.string().required(),
                    otherwise: Yup.string()
                  }),
                  description: Yup.string().when('partNumber', {
                    is: (partNumber: string) => partNumber?.length > 0,
                    then: Yup.string().required(),
                    otherwise: Yup.string()
                  }),
                  quantity: Yup.number().when(['description', 'partNumber'], {
                    is: (description: string, partNumber: string) => description?.length > 0 && partNumber?.length > 0,
                    then: Yup.number().positive().required(),
                    otherwise: Yup.number()
                  })
                },
                [['description', 'partNumber']]
              )
            ),
            partsPriorityId: Yup.number().positive().min(1).required(),
            partsNeededDate: Yup.string().required().trim(),
            partsRequestReasoningId: Yup.number().when('quoteRequestType', {
              is: (quoteRequestType: number) => Number(quoteRequestType) === 0,
              then: Yup.number().positive().min(1).required()
            }),
            serviceRequestReasoningId: Yup.number().when('quoteRequestType', {
              is: (quoteRequestType: number) => Number(quoteRequestType) > 0,
              then: Yup.number().positive().min(1).required()
            }),
            isPlannedOutage: Yup.string().required().trim(),
            isCustomer: Yup.string().required().trim(),

            outageSeasonId: Yup.number().when('isPlannedOutage', {
              is: (isCustomer: string) => isCustomer === 'true',
              then: Yup.number().positive().min(1).required()
            }),
            outageYear: Yup.number().when('isPlannedOutage', {
              is: (isCustomer: string) => isCustomer === 'true',
              then: Yup.number().positive().min(1).required()
            }),
            equipmentId: Yup.number().positive().min(1).required(),
            equipmentName: Yup.string().required().trim(),
            customerContactId: Yup.number().when('isCustomer', {
              is: (isCustomer: string) => isCustomer === 'true',
              then: Yup.number().positive().min(1).required()
            }),
            internalOutageId: Yup.string()
          }),
          // nameplate: Yup.object().shape({
          //   name: Yup.string().required().trim(),
          //   quoteRequestAttachmentTypeId: Yup.number().positive().min(1).required(),
          //   file: Yup.string().required().trim(),
          // }),
          isUnitSelected: Yup.boolean().required(),
          isProductSelected: Yup.boolean().required()
        })}
        validateOnChange={true} // 20230131 - Set validateOnChange to true since there is no reason it shouldnt be true all the time.
        validateOnBlur={true} // 20230131 - Set validateOnBlur to true since there is no reason it shouldnt be true all the time.
        onSubmit={handleOnSubmit}
      >
        {(formikProps) => {
          // 20230131 - Added a check for isFormSubmitSuccess.api1 so we could reset form and remove the isBlocked property.

          if (isFormSubmitSuccess.api1) {
            setTimeout(() => {
              formikProps.resetForm();
              setResetInitialValues(false);
              setIsBlocked(false);
            }, 0);
          } else {
            setTimeout(() => setIsBlocked(formikProps.dirty), 0);
          }

          if (formikProps.submitCount > 0) {
            setTimeout(() => setIsSubmitted(true), 0);
          } else {
            setTimeout(() => setIsSubmitted(false), 0);
          }

          // 20230131 - Added this check to reset initial form values if the user changes baseOrg while adding a quote request.
          if (resetInitialValues) {
            setTimeout(() => {
              formikProps.resetForm();
              setResetInitialValues(false);
            }, 0);
          }

          const isValidOnBehalfOfCustomerSection =
            formikProps.getFieldMeta('quoteRequest.isCustomer').value === 'false' ||
            (!formikProps.getFieldMeta('quoteRequest.dateRequestReceivedFromCustomer').error &&
              !formikProps.getFieldMeta('quoteRequest.customerContactId').error);

          return (
            <Form>
              {(isLoadingAddQuoteRequest || isLoadingAddAttachment || isLoadingAddNameplate) && (
                <ModalGlobal
                  modaltitle={''}
                  modalSize={'md'}
                  isModalHeader={false}
                >
                  <Loader containerStyle={{ marginTop: '50px' }} />
                </ModalGlobal>
              )}
              {QuoteTypesLoading || RequestTypeLoading ? (
                <Loader containerStyle={{ marginTop: '100px', marginBottom: '100px' }} />
              ) : (
                <>
                  {user?.mpInternal && (
                    <>
                      <hr />
                      <div>
                        <h4>For Internal Mitsubishi Power Only</h4>
                      </div>

                      <div>
                        <RadioButtonsGrouped
                          name="quoteRequest.isCustomer"
                          display="inline"
                          firstRadioName="Yes"
                          firstRadioValue="true"
                          SecondRadioName="No"
                          SecondRadioValue="false"
                          labelName="Are you submitting this new quote request on behalf of a customer?*"
                          className=""
                        />
                      </div>

                      {formikProps.values.quoteRequest.isCustomer === 'true' && <InternalUserField />}

                      <div className={classes.flex}>
                        <div className={classes.flexCol}>
                          <TextInputField
                            type="text"
                            name="quoteRequest.internalOutageId"
                            tooltip={{
                              text: 'CRM Outage ID'
                            }}
                            labelName="Internal Outage ID"
                            className="marginbottom0"
                            placeholder="Add internal outage ID"
                          />
                        </div>
                        <div className={classes.flexCol}></div>
                      </div>
                    </>
                  )}
                  <hr />
                  <div>
                    <h4>General Request Details</h4>
                  </div>

                  <div className={classes.flex}>
                    <div className={classes.flexCol}>
                      <SelectFieldComplex
                        disabled={isGeneralRequestSelected}
                        defaultValue={-1}
                        options={requestTypeOption}
                        name={'quoteRequest.quoteRequestType'}
                        labelName={'Request Type*'}
                        getSelectedOption={(val) => setQuoteRequestTypeId(val)}
                      />
                    </div>
                    <div className={classes.flexCol}>
                      <SelectField
                        disabled={isGeneralRequestSelected}
                        defaultValue={0}
                        options={quoteTypeOption}
                        name={'quoteRequest.quoteTypeId'}
                        labelName={'Quote Type*'}
                        getSelectedOption={(val) => setQuoteTypeId(val)}
                      />
                    </div>
                  </div>

                  <hr />

                  {!isGeneralRequestSelected && (
                    <>
                      <div className="flex align-center">
                        <div className="flex-grow-1 flex">
                          <Button
                            text={'Continue'}
                            type="button"
                            disabled={!(Number(quoteRequestTypeId) >= 0 && Number(quoteTypeId) > 0 && isValidOnBehalfOfCustomerSection)}
                            onClick={() => setIsGeneralRequestSelected(true)}
                            className="primaryBtn darkBlue "
                          />
                          <Button
                            text="Cancel"
                            onClick={() => navigate('..')}
                            type="button"
                            className="primaryBtn whiteBtn marginLeft16"
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {isGeneralRequestSelected && (
                    <>
                      <QuoteRequestFormStepTwo handleSubmitNew={handleSubmitNew} />
                      <hr />
                      <div className="flex align-center">
                        <div className="flex-grow-1 flex">
                          <button
                            type="submit"
                            className="primaryBtn darkBlue"
                            disabled={!formikProps.dirty || formikProps.isSubmitting || !formikProps.isValid}
                          >
                            Submit Quote Request
                          </button>
                          <Button
                            text="Cancel"
                            onClick={() => navigate('..')}
                            type="button"
                            className="primaryBtn whiteBtn marginLeft16"
                          />
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default QuoteRequestFormStepOne;
