import { Field, Form, Formik, FormikHelpers } from "formik";
import React, { useContext, useEffect, useState } from "react";
import {
  useCreate_CustomerRequest,
  useGetAll_QuoteRequestType,
  useGetAll_QuoteTypes,
} from "../../../containers/PartsAndService/QuoteRequestHttpServices";
import { useOnError } from "../../../customHooks/useOnError";
import { CPApiResponse, QuoteRequestGetAll, RequestQuoteFormInterface, User } from "../../../utils/interface";
import RadioButtonsGrouped from "../../UI/BasicForm/RadioButtonsGrouped/RadioButtonsGrouped";
import TextInputField from "../../UI/BasicForm/TextInputField/TextInputField";
import classes from "../QuoteRequestForm.module.css";

import moment from "moment";
import { useIsFetching } from "react-query";
import { Store } from "../../../Store";
import { useGetAllByBaseOrg_Users } from "../../../containers/Administration/ListApiMethods";
import {
  useGetAll_OutageSeasons,
  useGetAll_PartsRequestReasons,
  useGetAll_RequestPriority,
} from "../../../containers/PartsAndService/QuoteRequestHttpServices";
import { queryKeys } from "../../../react-query/queryKeys";
import { queryClient } from "../../../react-query/useQueryClientGet";
import { ItemTypeCatalogIds, QuoteRequestAttachmentTypeID } from "../../../utils/moduleIDs";
import Loader from "../../Loader/Loader";
import TextAreaField from "../../UI/BasicForm/TextAreaField/TextAreaField";
import QuoteRequestFormStepThreeDisabled from "./QuoteRequestFormStepThreeDisabled";

interface quoteProps {
  quoteRequestItem: QuoteRequestGetAll;
  isExternal: boolean;
  isEventClosed: boolean;
  getDirtyFlagTwo?(value: boolean): void;
}
const QuoteRequestFormDisabled = ({ quoteRequestItem, isExternal, isEventClosed, getDirtyFlagTwo }: quoteProps) => {
  const { selectedPlant } = useContext(Store);
  const setErrorData = useOnError();
  const isFetching = useIsFetching([queryKeys.getQuoteRequest]);
  const [quoteRequestList, setQuoteRequestList] = useState(quoteRequestItem);
  const [isServiceRequest, setIsServiceRequest] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState(false);

  const [quoteRequestId, setQuoteRequestId] = useState<number>(0);
  const [PartsDocumentBase64, setPartsDocumentBase64] = useState<string>("");

  const [contactType, setContactType] = useState<{ id: number; name: string }[]>([
    { id: quoteRequestList.customerContactId, name: `${quoteRequestList.customerContact}` },
  ]);
  const [requestTypeOption, setRequestTypeOption] = useState<{ id: number; name: string }[]>([]);
  const [quoteTypeOption, setQuoteTypeOption] = useState<{ id: number; name: string }[]>([{ id: 0, name: "Select a quote type" }]);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [requestPriority, setRequestPriority] = useState<{ id: number; name: string }[]>([{ id: 0, name: "Select a priority" }]);
  const [partsRequestReasons, setPartsRequestReasons] = useState<{ id: number; name: string }[]>([{ id: 0, name: "Select a reason" }]);
  const [outageSeasons, setOutageSeasons] = useState<{ id: number; name: string }[]>([{ id: 0, name: "Select a outage seasons" }]);

  useEffect(() => {
    setQuoteRequestList(quoteRequestItem);
    setIsServiceRequest(Number(quoteRequestItem.itemTypeId) === ItemTypeCatalogIds.ServiceQuoteRequest);
  }, [quoteRequestItem]);

  useEffect(() => {
    getDirtyFlagTwo && getDirtyFlagTwo(isDirty);
  }, [isDirty]);

  // Api fetch start-----------------------------------------------------//
  const onSuccessQRT = (quoteRequestType: any) => {
    setRequestTypeOption(quoteRequestType.data);
  };
  const onErrorQRT = () => {};
  useGetAll_QuoteRequestType(onSuccessQRT, onErrorQRT);
  // Api fetch end-------------------------------------------------------//

  // Api fetch start-----------------------------------------------------//
  const onSuccessQT = (quoteType: any) => {
    setQuoteTypeOption(quoteType.data.map((d: { quoteTypeId: number; name: string }) => ({ id: d.quoteTypeId, name: d.name })));
  };
  const onErrorQT = () => {};
  useGetAll_QuoteTypes(onSuccessQT, onErrorQT);
  // Api fetch end-------------------------------------------------------//

  //{***************************************************************************************************************************}

  const onSuccessGetAllByBaseOrg = (users: CPApiResponse<User[]>) => {
    const allUsers = users.data.map((contact) => ({ id: contact.id, name: `${contact.fName} ${contact.lName}` }));

    if (allUsers.length > 0) {
      setContactType([{ id: 0, name: "Select Customer" }, ...allUsers]);
    } else {
      setContactType(contactType);
    }
  };

  const onErrorGetAllByBaseOrg = (error: unknown) => {
    setErrorData(error);
  };

  useGetAllByBaseOrg_Users(selectedPlant.baseOrgId, onSuccessGetAllByBaseOrg, onErrorGetAllByBaseOrg);

  // Api fetch start-----------------------------------------------------//
  const onSuccessRP = (requestPriority: { data: [{ id: number; order: number; title: string }] }) => {
    setRequestPriority(
      requestPriority.data.map((option: { id: number; order: number; title: string }) => ({ id: option.id, name: option.title.toLowerCase() }))
    );
  };
  const onErrorRP = (error: any) => {
    setRequestPriority([]);
  };
  useGetAll_RequestPriority(onSuccessRP, onErrorRP);
  // Api fetch end-------------------------------------------------------//

  // Api fetch start-----------------------------------------------------//
  const onSuccessPRR = (partsRequestReasons: { data: [{ reasoningId: number; name: string; description: string }] }) => {
    setPartsRequestReasons(
      partsRequestReasons.data.map((option: { reasoningId: number; name: string; description: string }) => ({
        id: option.reasoningId,
        name: option.name.toLowerCase(),
      }))
    );
  };
  const onErrorPRR = (error: any) => {
    setPartsRequestReasons([]);
  };
  useGetAll_PartsRequestReasons(onSuccessPRR, onErrorPRR, !isServiceRequest);
  // Api fetch end-------------------------------------------------------//
  // Api fetch start-----------------------------------------------------//
  const onSuccessOS = (partsRequestReasons: { data: [{ outageSeasonId: number; name: string; description: string }] }) => {
    setOutageSeasons(
      partsRequestReasons.data.map((option: { outageSeasonId: number; name: string; description: string }) => ({
        id: option.outageSeasonId,
        name: option.name.toLowerCase(),
      }))
    );
  };
  const onErrorOS = (error: any) => {
    setOutageSeasons([]);
  };
  useGetAll_OutageSeasons(onSuccessOS, onErrorOS);
  // Api fetch end-------------------------------------------------------//
  //{***************************************************************************************************************************}

  const { mutate: CreateCustomerRequest } = useCreate_CustomerRequest();

  const handleOnSubmit = (values: RequestQuoteFormInterface, formikHelpers: FormikHelpers<RequestQuoteFormInterface>) => {
    // console.log();

    const payload = {
      globalItemId: values.quoteRequest.globalItemId ? values.quoteRequest.globalItemId : 0,
      additionalComments: values.quoteRequest.additionalComments,
    };
    CreateCustomerRequest(payload, {
      onSuccess: (quoteResponse) => {
        queryClient.invalidateQueries([queryKeys.getQuoteRequest, quoteResponse.data[0].globalItemId]);
      },
      onError: (error: unknown) => {
        setErrorData(error);
      },
    });
  };

  let initailValues: RequestQuoteFormInterface;

  initailValues = {
    quoteRequest: {
      quoteRequestId: quoteRequestList?.quoteRequestId,
      globalItemId: quoteRequestList?.globalItemId,
      baseOrgId: quoteRequestList?.baseOrgId,
      itemTypeId: quoteRequestList?.itemTypeId,
      description: quoteRequestList?.description,
      quotePriorityId: quoteRequestList?.quotePriorityId,
      quotePriority: quoteRequestList?.quotePriority,
      plantSiteId: quoteRequestList?.plantSiteId,
      quoteNeededDate: `${moment(quoteRequestList?.quoteNeededDate).format("YYYY-MM-DD")}`,
      // dateRequestReceivedFromCustomer: `2018-07-22`,
      dateRequestReceivedFromCustomer: quoteRequestList?.dateRequestReceivedFromCustomer
        ? `${moment(quoteRequestList?.dateRequestReceivedFromCustomer).format("YYYY-MM-DD")}`
        : "",
      partsPriorityId: quoteRequestList?.partsPriorityId,
      partsNeededDate: `${moment(quoteRequestList?.partsNeededDate).format("YYYY-MM-DD")}`,
      partsRequestReasoningId: quoteRequestList?.partsRequestReasoningId,
      isPlannedOutage: `${quoteRequestList?.isPlannedOutage}`,
      isCustomer: "true",
      outageSeasonId: quoteRequestList?.outageSeasonId,
      outageYear: quoteRequestList?.outageYear,
      equipmentId: quoteRequestList?.equipmentId,
      equipmentName: quoteRequestList?.equipmentName,
      customerContactId: quoteRequestList?.customerContactId,
      additionalComments: (quoteRequestList?.additionalComments || ''),
      internalOutageId: quoteRequestList?.internalOutageId,
      file: "",
      quoteRequestParts: quoteRequestList?.quoteRequestParts,
      quoteRequestEvents: quoteRequestList.quoteRequestEvents ? quoteRequestList?.quoteRequestEvents : [],
      quoteRequestAttachments: quoteRequestList?.quoteRequestAttachments,
      quoteRequestType: quoteRequestList?.quoteRequestType,
      quoteTypeId: quoteRequestList?.quoteTypeId,
      customerContact: quoteRequestList?.customerContact,
      requestTypeName: quoteRequestList?.requestTypeName,
      createdBy: quoteRequestList?.createdBy,
      serviceRequestReasoningId: 0,
    },
    partsDocument: {
      quoteRequestId: quoteRequestId,
      name: "",
      quoteRequestAttachmentTypeId: QuoteRequestAttachmentTypeID.PartsDocument,
      file: null,
    },
    nameplate: {
      quoteRequestId: quoteRequestId,
      name: "",
      quoteRequestAttachmentTypeId: QuoteRequestAttachmentTypeID.NameplateAsset,
       file: null,
    },
    isUnitSelected: null,
    isProductSelected: null,
    partsDocumentBase64: "",
    nameplateBase64: "",

    unit: 0,
    productType: 0,
  };

  return (
    <div>
      <Formik enableReinitialize initialValues={initailValues} onSubmit={handleOnSubmit}>
        {(formikProps) => {
          const dirtFunction = () => {
            const meta = formikProps.getFieldMeta;

            const { error, initialValue, value } = meta("quoteRequest.additionalComments");
            if (error === undefined && value !== initialValue) {
              setIsDirty(true);
            } else {
              setIsDirty(false);
            }
          };
          
          setTimeout(() => dirtFunction(), 0);

          return (
            <Form >
              {isFetching > 0 ? (
                <Loader containerStyle={{marginTop: "50px"}} />
              ) : (
                <>
                  <div>
                    <h4>General Request Details</h4>
                  </div>
                  <p className={classes.para}>
                    This page is the central location for users to request parts and service quotations. It documents requests made through the page
                    and Mitsubishi Power Americas’ responses. All members of the Customer Portal group for the plant site will receive email
                    notifications when requests are made, and responses are posted.
                  </p>
                  <div className={classes.flex}>
                    <div className={classes.flexCol}>
                      <div style={{ height: "32px" }}>
                        <label>Request Type*</label>
                      </div>
                      <Field as={"select"}  disabled name={"quoteRequest.quoteRequestType"}>
                        {requestTypeOption.map((ct) => (
                          <option key={ct.id + ct.name} value={ct.id}>
                            {ct.name}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <div className={classes.flexCol}>
                      <div style={{ height: "32px" }}>
                        <label>Quote Type*</label>
                      </div>
                      <Field as={"select"} className="capitalize" disabled name={"quoteRequest.quoteTypeId"}>
                        {quoteTypeOption.map((ct) => (
                          <option key={ct.id + ct.name} value={ct.id}>
                            {ct.name}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>
                  <hr />

                  <>
                    <>
                      <div>
                        <TextAreaField
                          name="quoteRequest.description"
                          height={64}
                          labelName="Request Description*"
                          placeholder="Description"
                          className="capitalize"
                          disabled
                        />
                      </div>
                      <div className={classes.flex}>
                        <div className={classes.flexCol}>
                          <div style={{ height: "32px" }}>
                            <label>Quote Turnaround Priority*</label>
                          </div>
                          <Field as={"select"} className="capitalize" disabled name={"quoteRequest.quotePriorityId"}>
                            {requestPriority.map((ct) => (
                              <option key={ct.id + ct.name} value={ct.id}>
                                {ct.name}
                              </option>
                            ))}
                          </Field>
                        </div>
                        <div className={classes.flexCol}>
                          <TextInputField
                            type="date"
                            name="quoteRequest.quoteNeededDate"
                            labelName="Quote Needed Date*"
                            className="capitalize"
                            placeholder="Quote Needed Date"
                            disabled
                          />
                        </div>
                      </div>
                      <div className={classes.flex}>
                        <div className={classes.flexCol}>
                          <div style={{ height: "32px" }}>
                            <label>{!isServiceRequest ? "Parts Delivery Priority*" : "Service Delivery Priority*"}</label>
                          </div>
                          <Field as={"select"} className="capitalize" disabled name={"quoteRequest.partsPriorityId"}>
                            {requestPriority.map((ct) => (
                              <option key={ct.id + ct.name} value={ct.id}>
                                {ct.name}
                              </option>
                            ))}
                          </Field>
                        </div>
                        <div className={classes.flexCol}>
                          <TextInputField
                            type="date"
                            name="quoteRequest.partsNeededDate"
                            labelName={!isServiceRequest ? "Parts Needed Date*" : "Service Needed Date*"}
                            className="capitalize"
                            placeholder="Quote Needed Date"
                            disabled
                          />
                        </div>
                      </div>
                      <div className={classes.flex}>
                        <div className={classes.flexCol}>
                          <div style={{ height: "32px" }}>
                            <label>Parts Request Reasoning*</label>
                          </div>
                          <Field as={"select"} className="capitalize" disabled name={"quoteRequest.partsRequestReasoningId"}>
                            {partsRequestReasons.map((ct) => (
                              <option key={ct.id + ct.name} value={ct.id}>
                                {ct.name}
                              </option>
                            ))}
                          </Field>
                        </div>
                        <div className={classes.flexCol}></div>
                      </div>
                      <div className={classes.flexCol}>
                        <div>
                          <RadioButtonsGrouped
                            name="quoteRequest.isPlannedOutage"
                            display="block"
                            firstRadioName="Yes"
                            firstRadioValue="true"
                            SecondRadioName="No"
                            SecondRadioValue="false"
                            labelName="Is this quote related to a planned outage?"
                            className=""                            
                            disabled
                          />
                        </div>
                      </div>
                      {formikProps.values.quoteRequest.isPlannedOutage === "true" && (
                        <div className={classes.flex}>
                          <div className={classes.flexCol}>
                            <div style={{ height: "32px" }}>
                              <label>Outage Season*</label>
                            </div>
                            <Field as={"select"} className="capitalize" disabled name={"quoteRequest.outageSeasonId"}>
                              {outageSeasons.map((ct) => (
                                <option key={ct.id + ct.name} value={ct.id}>
                                  {ct.name}
                                </option>
                              ))}
                            </Field>
                          </div>
                          <div className={classes.flexCol}>
                            <TextInputField
                              name="quoteRequest.outageYear"
                              labelName="Outage Year*"
                              className=""
                              placeholder="Quote Needed Date"
                              disabled
                            />
                          </div>
                        </div>
                      )}

                      <hr />
                      <QuoteRequestFormStepThreeDisabled
                        isServiceRequest={isServiceRequest}
                        isExternal={isExternal}
                        isEventClosed={isEventClosed}
                        quoteRequestList={quoteRequestList}
                      />
                    </>

                    <div className="flex align-center">
                      <div className="flex-grow-1 flex">
                        <button type="submit" className="primaryBtn darkBlue" disabled={isExternal || !formikProps.isValid || !isDirty}>
                          Update
                        </button>
                        {/* <button type="submit" className="primaryBtn darkBlue">
                        Submit Quote Request
                      </button> */}
                      </div>
                    </div>
                  </>
                </>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default QuoteRequestFormDisabled;
