import {
  TypeDTO,
  RequestPriorityDTO,
  QuoteFormInterfaceDTO,
  CPApiResponse,
  QuoteRequestAttachment,
  CustomerRequestInterface,
  QuoteStatusInterface,
  quoteAttachmentFormInterface,
  base64Interface,
} from "../../utils/interface";
import {
  apiDeleteAttachment,
  apiFile,
  apiGetAllOutageSeasons,
  apiGetAllPartsRequestReasons,
  apiGetAllServiceRequestReasons,
  apigetAllQuoteRequestTypes,
  apiGetAllQuoteTypes,
  apigetAllRequestPriority,
  apiGetQuoteRequestAttachment,
  apiGetQuoteRequestAttachmentByType,
  apiGetQuoteRequestAttachmentList,
  apiquoterequest,
  apiquoterequestCreateAttachment,
  apiUpdateQuoteAttachment,
  apiUpdateQuoteStatus,
} from "../../endpoints/endpoints";

import axios, { AxiosResponse } from "axios";
import { useMutation, useQueries, useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { queryKeys } from "../../react-query/queryKeys";
import { toBase64 } from "../../utils/FileUtils";

export interface QuoteRequestGetAllMethods<A, B, C> {
  getAllQuoteRequestType: () => Promise<A>;
  getAllRequestPriority: () => Promise<B>;
  getAllOutageSeasons: () => Promise<B>;
  getAllPartsRequestReasons: () => Promise<B>;
  getAllServiceRequestReasons: () => Promise<B>;
  getAllQuoteTypes: () => Promise<B>;
  // create: (data: string) => Promise<C>;
  // update: (data: string) => Promise<C>;
  //   delete: (data: D) => Promise<DR>;
}

export const QuoteRequestGetAllHttpServices: QuoteRequestGetAllMethods<
  AxiosResponse<TypeDTO>,
  AxiosResponse<RequestPriorityDTO>,
  QuoteFormInterfaceDTO
> = {
  getAllQuoteRequestType: async (): Promise<AxiosResponse<TypeDTO>> => {
    const response = await axios(`${apigetAllQuoteRequestTypes}`);
    return response.data;
  },
  getAllRequestPriority: async (): Promise<AxiosResponse<RequestPriorityDTO>> => {
    const response = await axios(`${apigetAllRequestPriority}`);
    return response.data;
  },
  getAllOutageSeasons: async (): Promise<AxiosResponse<RequestPriorityDTO>> => {
    const response = await axios(`${apiGetAllOutageSeasons}`);
    return response.data;
  },
  getAllPartsRequestReasons: async (): Promise<AxiosResponse<RequestPriorityDTO>> => {
    const response = await axios(`${apiGetAllPartsRequestReasons}`);
    return response.data;
  },
  getAllServiceRequestReasons: async (): Promise<AxiosResponse<RequestPriorityDTO>> => {
    const response = await axios(`${apiGetAllServiceRequestReasons}`);
    return response.data;
  },
  getAllQuoteTypes: async (): Promise<AxiosResponse<RequestPriorityDTO>> => {
    const response = await axios(`${apiGetAllQuoteTypes}`);
    return response.data;
  },
};

export const useGetAll_QuoteRequestType = (onSuccess: any, onError: any) => {
  return useQuery(queryKeys.getAllQuoteRequestType, QuoteRequestGetAllHttpServices.getAllQuoteRequestType, {
    onSuccess,
    onError,
  });
};
export const useGetAll_RequestPriority = (onSuccess: any, onError: any) => {
  return useQuery(queryKeys.getAllRequestPriority, QuoteRequestGetAllHttpServices.getAllRequestPriority, {
    onSuccess,
    onError,
  });
};
export const useGetAll_OutageSeasons = (onSuccess: any, onError: any) => {
  return useQuery(queryKeys.getAllOutageSeasons, QuoteRequestGetAllHttpServices.getAllOutageSeasons, {
    onSuccess,
    onError,
  });
};
export const useGetAll_PartsRequestReasons = (onSuccess: any, onError: any, enabled: boolean) => {
  return useQuery(queryKeys.getAllPartsRequestReasons, QuoteRequestGetAllHttpServices.getAllPartsRequestReasons, {
    onSuccess,
    onError,
    enabled
  });
};
export const useGetAll_ServiceRequestReasons = (onSuccess: any, onError: any, enabled: boolean) => {
  return useQuery(queryKeys.getAllServiceRequestReasons, QuoteRequestGetAllHttpServices.getAllServiceRequestReasons, {
    onSuccess,
    onError,
    enabled
  });
};
export const useGetAll_QuoteTypes = (onSuccess: any, onError: any) => {
  return useQuery(queryKeys.getAllQuoteTypes, QuoteRequestGetAllHttpServices.getAllQuoteTypes, {
    onSuccess,
    onError,
  });
};

export interface QuoteRequestCrudMethods<A, B, C, D, E, F, G> {
  create: (data: FormData) => Promise<A>;
  createCustomerRequest: (data: CustomerRequestInterface) => Promise<A>;
  UpdateQuoteStatus: (data: QuoteStatusInterface) => Promise<A>;
  UpdateQuoteAttachment: (data: quoteAttachmentFormInterface) => Promise<E>;
  createQuoteRequestAttachment: (data: {
    quoteRequestId: number;
    name: string;
    quoteRequestAttachmentTypeId: number;
    file: File | null;
  }) => Promise<A>;
  createAttachment: (data: quoteAttachmentFormInterface) => Promise<A>;
  getAttachmentList: (quoteRequestId: number) => Promise<B>;
  getAttachmentByType: (quoteRequestId: number, quoteRequestAttachmentTypeId: number) => Promise<C>;
  getAttachmentByid: (QuoteRequestAttachmentId: number) => Promise<B>;
  deleteAttachment: (QuoteRequestAttachmentId: number) => Promise<E>;
  getFile: (id: number) => Promise<string>;
  getFileBlob: (id: number) => Promise<F>;
  getFiles: (attachment: QuoteRequestAttachment) => Promise<base64Interface>;
  getPartstFileAsBase64: (id: number) => Promise<D>;
  getPartstFileSasBase64: (ids: number[]) => Promise<D[]>;
  getNamePlatetFileAsBase64: (id: number) => Promise<D>;
  getFileAsAxiosResponseBlob: (id: number) => Promise<G>;
  //   delete: (data: D) => Promise<DR>;
}

export const QuoteRequestCrudHttpServices: QuoteRequestCrudMethods<
  QuoteFormInterfaceDTO,
  CPApiResponse<QuoteRequestAttachment>,
  CPApiResponse<QuoteRequestAttachment[]>,
  string,
  CPApiResponse<boolean>,
  Blob,
  AxiosResponse<Blob>
> = {
  create: async (data: FormData): Promise<QuoteFormInterfaceDTO> => {
    const response = await axios({
      url: `${apiquoterequest}`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    });
    return response.data;
  },
  deleteAttachment: async (QuoteRequestAttachmentId: number): Promise<CPApiResponse<boolean>> => {
    const response = await axios({
      url: `${apiDeleteAttachment}/${QuoteRequestAttachmentId}`,
      method: "DELETE",
    });
    return response.data;
  },
  createCustomerRequest: async (values: CustomerRequestInterface): Promise<QuoteFormInterfaceDTO> => {
    const formdata = new FormData();
    const formValues: any = values;
    for (let formvalue in formValues) {
      formdata.append(formvalue, formValues[formvalue]);
    }
    const response = await axios({
      url: `${apiquoterequest}/${values.globalItemId}`,
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formdata,
    });
    return response.data;
  },
  UpdateQuoteStatus: async (values: QuoteStatusInterface): Promise<QuoteFormInterfaceDTO> => {
    const response = await axios({
      url: `${apiUpdateQuoteStatus}`,
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: values,
    });
    return response.data;
  },
  UpdateQuoteAttachment: async (data: quoteAttachmentFormInterface): Promise<CPApiResponse<boolean>> => {
    const response = await axios({
      url: `${apiUpdateQuoteAttachment}`,
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    });
    return response.data;
  },
  createQuoteRequestAttachment: async (data: {
    quoteRequestId: number;
    name: string;
    quoteRequestAttachmentTypeId: number;
    file: File | null;
  }): Promise<QuoteFormInterfaceDTO> => {
    const response = await axios({
      url: `${apiquoterequestCreateAttachment}`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    });
    return response.data;
  },
  createAttachment: async (data: quoteAttachmentFormInterface): Promise<QuoteFormInterfaceDTO> => {
    const response = await axios({
      url: `${apiquoterequestCreateAttachment}`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    });
    return response.data;
  },
  getAttachmentList: async (quoteRequestId: number): Promise<CPApiResponse<QuoteRequestAttachment>> => {
    const response = await axios({
      url: `${apiGetQuoteRequestAttachmentList}/${quoteRequestId}`,
      method: "GET",
    });
    return response.data;
  },
  getAttachmentByType: async (quoteRequestId: number, quoteRequestAttachmentTypeId: number): Promise<CPApiResponse<QuoteRequestAttachment[]>> => {
    const response = await axios({
      url: `${apiGetQuoteRequestAttachmentByType}/${quoteRequestId}/${quoteRequestAttachmentTypeId}`,
      method: "GET",
    });
    return response.data;
  },
  getAttachmentByid: async (quoteRequestAttachmentId: number): Promise<CPApiResponse<QuoteRequestAttachment>> => {
    const response = await axios({
      url: `${apiGetQuoteRequestAttachment}/${quoteRequestAttachmentId}`,
      method: "GET",
    });
    return response.data;
  },
  getFile: async (id: number): Promise<string> => {
    const response = await axios({
      url: `${apiFile}/DisplayFileByFileId/${id}`,
      method: "GET",
      responseType: "blob",
    });

    const base64File = toBase64(response.data)
      .then((Base64: string) => {
        return Base64;
      })
      .catch((err: Error) => {
        return "";
      });

    return base64File;
  },
  getFileBlob: async (id: number): Promise<Blob> => {
    const response = await axios({
      url: `${apiFile}/DisplayFileByFileId/${id}`,
      method: "GET",
      responseType: "blob",
    });

    return response.data;
  },
  getFiles: async (attachment: QuoteRequestAttachment): Promise<base64Interface> => {
    const response = await axios({
      url: `${apiFile}/DisplayFileByFileId/${attachment.fileId}`,
      method: "GET",
      responseType: "blob",
    });

    const base64File = toBase64(response.data)
      .then((Base64: string) => {
        return {
          ...attachment,
          fileBase64: Base64,
        };
      })
      .catch((err: Error) => {
        return { ...attachment, fileBase64: "" };
      });

    return base64File;
  },
  getPartstFileAsBase64: async (id: number): Promise<string> => {
    const response = await axios({
      url: `${apiFile}/DisplayFileByFileId/${id}`,
      method: "GET",
      responseType: "blob",
    });

    const base64File = toBase64(response.data)
      .then((Base64: string) => {
        return Base64;
      })
      .catch((err: Error) => {
        return "";
      });

    return base64File;
  },
  getPartstFileSasBase64: async (ids: number[]): Promise<string[]> => {
    let fileIdsBase64: string[] = [];
    if (ids.length > 0) {
      ids.forEach((id) => {
        axios({
          url: `${apiFile}/DisplayFileByFileId/${id}`,
          method: "GET",
          responseType: "blob",
        }).then((res) => {
          toBase64(res.data)
            .then((Base64: string) => {
              return fileIdsBase64.push(Base64);
            })
            .catch((err: Error) => {
              return fileIdsBase64.push("");
            });
        });
      });

      return fileIdsBase64;
    }
    return fileIdsBase64;
  },
  getNamePlatetFileAsBase64: async (id: number): Promise<string> => {
    const response = await axios({
      url: `${apiFile}/DisplayFileByFileId/${id}`,
      method: "GET",
      responseType: "blob",
    });

    const base64File = toBase64(response.data)
      .then((Base64: string) => {
        return Base64;
      })
      .catch((err: Error) => {
        return "";
      });

    return base64File;
  },
  getFileAsAxiosResponseBlob: async (id: number): Promise<AxiosResponse<Blob>> => {
    const response = await axios({
      url: `${apiFile}/DisplayFileByFileId/${id}`,
      method: "GET",
      responseType: "blob",
    });
    
    return response;
  }
};

export const useCreate_RequestQuote = () => {
  return useMutation(QuoteRequestCrudHttpServices.create);
};
export const useDelete_Attachment = () => {
  return useMutation(QuoteRequestCrudHttpServices.deleteAttachment);
};
export const useCreate_CustomerRequest = () => {
  return useMutation(QuoteRequestCrudHttpServices.createCustomerRequest);
};
export const useUpdate_QuoteStatus = () => {
  return useMutation(QuoteRequestCrudHttpServices.UpdateQuoteStatus);
};
export const useUpdate_QuoteAttachment = () => {
  return useMutation(QuoteRequestCrudHttpServices.UpdateQuoteAttachment);
};
export const useCreate_QuoteRequestAttachment = () => {
  return useMutation(QuoteRequestCrudHttpServices.createQuoteRequestAttachment);
};
export const useCreate_Attachment = () => {
  return useMutation(QuoteRequestCrudHttpServices.createAttachment);
};
export const useGet_AttachmentList = (
  quoteRequestId: number,
  options: Omit<UseQueryOptions<CPApiResponse<QuoteRequestAttachment>>, "queryKey" | "queryFn"> = {}
) => {
  return useQuery<CPApiResponse<QuoteRequestAttachment>>(
    [queryKeys.getQuoteRequestAttachments, quoteRequestId],
    () => QuoteRequestCrudHttpServices.getAttachmentList(quoteRequestId),
    {
      ...options,
    }
  );
};
export const useGet_AttachmentByType = (
  quoteRequestId: number,
  quoteRequestAttachmentTypeId: number,
  options: Omit<UseQueryOptions<CPApiResponse<QuoteRequestAttachment[]>>, "queryKey" | "queryFn"> = {}
) => {
  return useQuery<CPApiResponse<QuoteRequestAttachment[]>>(
    [queryKeys.getQuoteRequestAttachments, quoteRequestId, quoteRequestAttachmentTypeId],
    () => QuoteRequestCrudHttpServices.getAttachmentByType(quoteRequestId, quoteRequestAttachmentTypeId),
    {
      ...options,
    }
  );
};

export const useGet_AttachmentByid = (
  quoteRequestAttachmentId: number,

  options: Omit<UseQueryOptions<CPApiResponse<QuoteRequestAttachment>>, "queryKey" | "queryFn"> = {}
) => {
  return useQuery<CPApiResponse<QuoteRequestAttachment>>(
    [queryKeys.getQuoteRequestAttachments, quoteRequestAttachmentId],
    () => QuoteRequestCrudHttpServices.getAttachmentByid(quoteRequestAttachmentId),
    {
      ...options,
    }
  );
};

export const useGet_FileByID = (id: number, options: Omit<UseQueryOptions<string>, "queryKey" | "queryFn"> = {}) => {
  return useQuery<string>([queryKeys.getQuoteRequestDocument, id], () => QuoteRequestCrudHttpServices.getFile(id), {
    ...options,
  });
};
export const useGet_PartsFileByIDS = (attachments: QuoteRequestAttachment[]) => {
  return useQueries(
    attachments.map((attachment) => {
      return {
        enabled: !!attachment.fileId,
        queryKey: [queryKeys.File, attachment.fileId],
        queryFn: () => QuoteRequestCrudHttpServices.getFiles(attachment),
      };
    })
  ) as UseQueryResult<base64Interface>[];
};

export const useGet_PartsFileByID = (id: number, options: Omit<UseQueryOptions<string>, "queryKey" | "queryFn"> = {}) => {
  return useQuery<string>([queryKeys.PartsFile, id], () => QuoteRequestCrudHttpServices.getPartstFileAsBase64(id), {
    ...options,
  });
};
export const useGet_NamePlateFileByID = (id: number, options: Omit<UseQueryOptions<string>, "queryKey" | "queryFn"> = {}) => {
  return useQuery<string>([queryKeys.NamePlateFile, id], () => QuoteRequestCrudHttpServices.getNamePlatetFileAsBase64(id), {
    ...options,
  });
};

export const useGet_FileBlob = (id: number, options: Omit<UseQueryOptions<Blob>, "queryKey" | "queryFn"> = {}) => {
  return useQuery<Blob>([queryKeys.DeleteFile, id], () => QuoteRequestCrudHttpServices.getFileBlob(id), {
    ...options,
  });
};

export const useGet_FileAxiosResponseBlob = (id: number, options: Omit<UseQueryOptions<AxiosResponse<Blob>>, "queryKey" | "queryFn"> = {}) => {
  return useQuery<AxiosResponse<Blob>>([queryKeys.DeleteFile, id], () => QuoteRequestCrudHttpServices.getFileAsAxiosResponseBlob(id), {
    ...options,
  });
};